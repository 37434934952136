<template>
  <div>
    <v-row>
      <v-col md="12" v-if="locationsGridShow">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9">
            <v-toolbar-title>Şehir Lokasyonları</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                autocomplete="off"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()" />
              <RoleProvider slug="LOCATION_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    @click="
                      cleanLocationModalAddUpdateForm(),
                        cleanSubSiteLocationModalAddUpdateForm(),
                        (locationModalAddUpdateOptions.process = 'add'),
                        (locationModalAddUpdateOptions.title = 'Lokasyon Ekle'),
                        (locationModalAddUpdate = true)
                    "
                    class="py-2 px-3 font-weight-bold"
                    v-if="canItPass">
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2" />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"></ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <CModal
      :title="locationModalAddUpdateOptions.title"
      size="xl"
      :closeOnBackdrop="false"
      class="locationModalAddUpdateFormModal"
      :show.sync="locationModalAddUpdate">
      <CRow class="align-items-center topRow">
        <v-col cols="2" class="text-center">
          <v-switch
            v-model="subsiteSwitch"
            :style="{ transform: 'scale(0.9)' }"
            hide-details
            inset
            small
            label="Alt Site"></v-switch>
        </v-col>
        <v-col cols="2" class="text-center">
          <v-select
            class="select"
            v-model="selectedSite"
            v-show="subsiteSwitch"
            :items="subSiteInfo"
            item-text="selectedSiteValue.name"
            item-value="selectedSiteValue"
            @change="getSubsiteLocation()">
          </v-select>
        </v-col>
        <CCol v-if="selectedSite.selected" sm="2">
          <CSelect
            label="SubSite Durumu"
            :value.sync="locationModalAddUpdateForm.subSite.status"
            :options="locationModalAddUpdateFormStatusOptions" />
        </CCol>

        <v-col cols="6" class="text-center">
          <span class="font-weight-bold">
            {{ " Dil Seçimi : " }}
          </span>
          <v-btn-toggle
            v-model="btnToggleLanguage"
            borderless
            mandatory
            :style="{ transform: 'scale(0.9)' }"
            style="width: 60%"
            active-class="secondary white--text">
            <v-btn
              small
              style="width: 50%; border-radius: 50px 0 0 50px"
              :class="{ red: !btnToggleLanguage }"
              class="ls-0 text-capitalize font-weight-bold">
              {{ "ingilizce" }}
            </v-btn>
            <v-btn
              small
              style="width: 50%; border-radius: 0 50px 50px 0"
              :class="{ red: btnToggleLanguage }"
              class="ls-0 text-capitalize font-weight-bold">
              {{ "almanca" }}
            </v-btn>
          </v-btn-toggle></v-col
        >
      </CRow>

      <CRow>
        <!-- TODO HTML image -->
        <CCol sm="12" lg="4">
          <div class="img-car-container">
            <div class="img-car-upload-delete-container">
              <div class="btn-icon-center">
                <font-awesome-icon
                  id="btnResimYukle"
                  icon="cloud-upload-alt"
                  size="2x"
                  title="Resim Yükle"
                  @click="clickImageUpload()" />
                <font-awesome-icon
                  id="btnResimSil"
                  icon="trash-alt"
                  size="2x"
                  class="ml-4"
                  title="Resmi Sil"
                  v-if="
                    locationModalAddUpdateForm.srcImage !==
                    ENV_URL_CITY_LOCATION + 'default_location.png'
                  "
                  @click="clickImageDelete()" />
              </div>
              <input
                id="inputLocationImageFile"
                type="file"
                class="files"
                ref="files"
                custom
                v-on:change="selectImage()" />
            </div>
            <CImg :src="locationModalAddUpdateForm.srcImage" fluid />
          </div>
        </CCol>
        <CCol>
          <CRow>
            <CCol sm="10">
              <b>Lokasyon Slug: </b>
              <span>{{ lokslug() }}</span>
            </CCol>
            <CCol sm="4">
              <CSelect
                label="Durum"
                :value.sync="locationModalAddUpdateForm.status"
                :options="locationModalAddUpdateFormStatusOptions" />
            </CCol>
            <CCol sm="4">
              <CSelect
                label="Footer Durumu"
                :value.sync="locationModalAddUpdateForm.footerStatus"
                :options="locationModalAddUpdateFormStatusOptions" />
            </CCol>
            <!-- TODO HTML Şehir -->
            <CCol sm="4">
              <CInput label="Şehir" v-model="locationModalAddUpdateForm.city" />
            </CCol>
            <!-- TODO HTML Lokasyon -->
          </CRow>
        </CCol>
      </CRow>
      <CRow class="justify-center mr-4 ml-4">
        <CCol sm="12">
          <CSelect
            label="Varsayılan Booking Form Lokasyonu"
            :value.sync="locationModalAddUpdateForm.bookingFormLocation"
            :options="locationsOfCity" />
        </CCol>
      </CRow>
      <CRow class="justify-center">
        <CCol sm="6">
          <CInput
            :label="`Lokasyon Adı Türkçe `"
            v-model="locationModalAddUpdateForm.locationName" />
        </CCol>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('locationname', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('locationname', `de`)"
            :loading="loadingAwsIcon" />
        </div>
        <CCol sm="5" v-if="!btnToggleLanguage">
          <CInput
            :label="`Lokasyon Adı İngilizce `"
            v-model="locationModalAddUpdateForm.locationNameEn" />
        </CCol>
        <CCol sm="5" v-if="btnToggleLanguage">
          <CInput
            :label="`Lokasyon Adı Almanca `"
            v-model="locationModalAddUpdateForm.locationNameDe" />
        </CCol>
      </CRow>
      <CRow class="justify-center">
        <CCol sm="6">
          <CInput
            v-if="selectedSite.selected"
            :label="`Başlık Türkçe - ${selectedSite.name}`"
            v-model="locationModalAddUpdateForm.subSite.content.tr.title" />
          <CInput
            v-else
            :label="`Başlık Türkçe`"
            v-model="locationModalAddUpdateForm.content.tr.title" />
        </CCol>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('title', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('title', `de`)"
            :loading="loadingAwsIcon" />
        </div>
        <CCol sm="5" v-if="!btnToggleLanguage">
          <CInput
            v-if="selectedSite.selected"
            :label="`Başlık İngilizce - ${selectedSite.name}`"
            v-model="locationModalAddUpdateForm.subSite.content.en.title" />
          <CInput
            v-else
            :label="`Başlık İngilizce `"
            v-model="locationModalAddUpdateForm.content.en.title" />
        </CCol>
        <CCol sm="5" v-if="btnToggleLanguage">
          <CInput
            v-if="selectedSite.selected"
            :label="`Başlık Almanca - ${selectedSite.name}`"
            v-model="locationModalAddUpdateForm.subSite.content.de.title" />
          <CInput
            v-else
            :label="`Başlık Almanca `"
            v-model="locationModalAddUpdateForm.content.de.title" />
        </CCol>
      </CRow>

      <CRow class="justify-center">
        <CCol sm="6">
          <CInput
            v-if="selectedSite.selected"
            :label="`Açıklama Türkçe - ${selectedSite.name}`"
            v-model="
              locationModalAddUpdateForm.subSite.content.tr.description
            " />
          <CInput
            v-else
            :label="`Açıklama Türkçe `"
            v-model="locationModalAddUpdateForm.content.tr.description" />
        </CCol>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('description', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('description', `de`)"
            :loading="loadingAwsIcon" />
        </div>

        <CCol sm="5" v-if="!btnToggleLanguage">
          <CInput
            v-if="selectedSite.selected"
            :label="`Açıklama İngilizce - ${selectedSite.name}`"
            v-model="
              locationModalAddUpdateForm.subSite.content.en.description
            " />
          <CInput
            v-else
            :label="`Açıklama İngilizce `"
            v-model="locationModalAddUpdateForm.content.en.description" />
        </CCol>
        <CCol sm="5" v-if="btnToggleLanguage">
          <CInput
            v-if="selectedSite.selected"
            :label="`Açıklama Almanca / ${selectedSite.name}`"
            v-model="
              locationModalAddUpdateForm.subSite.content.de.description
            " />
          <CInput
            v-else
            :label="`Açıklama Almanca `"
            v-model="locationModalAddUpdateForm.content.de.description" />
        </CCol>
      </CRow>

      <CRow class="justify-center">
        <CCol sm="6">
          <ckeditor
            v-if="selectedSite.selected"
            v-model="
              locationModalAddUpdateForm.subSite.content.tr.richText
            "></ckeditor>
          <ckeditor
            v-else
            v-model="locationModalAddUpdateForm.content.tr.richText"></ckeditor>
        </CCol>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('ckeditor', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('ckeditor', `de`)"
            :loading="loadingAwsIcon" />
        </div>

        <CCol sm="5" v-if="!btnToggleLanguage">
          <ckeditor
            v-if="selectedSite.selected"
            v-model="
              locationModalAddUpdateForm.subSite.content.en.richText
            "></ckeditor>
          <ckeditor
            v-else
            v-model="locationModalAddUpdateForm.content.en.richText"></ckeditor>
        </CCol>
        <CCol sm="5" v-else>
          <ckeditor
            v-if="selectedSite.selected"
            v-model="
              locationModalAddUpdateForm.subSite.content.de.richText
            "></ckeditor>
          <ckeditor
            v-else
            v-model="locationModalAddUpdateForm.content.de.richText"></ckeditor>
        </CCol>
        <CCol sm="6" v-else>
          <ckeditor
            v-model="locationModalAddUpdateForm.content.de.richText"></ckeditor>      
           </CCol>
      </CRow>

      <CRow class="justify-center mt-3">
        <CCol sm="6">
          <label>Micro Data (Türkçe) {{ selectedSite.name }}</label>
          <CCard>
            <CCardBody v-if="selectedSite.selected">
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.subSite.microData
                  .tr"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.subSite.microData.tr.length -
                        1 ==
                      i
                    "
                    @click="microDataAdd('tr')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'tr')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardBody v-else>
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.microData.tr"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.microData.tr.length - 1 == i
                    "
                    @click="microDataAdd('tr')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'tr')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('microData', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('microData', `de`)"
            :loading="loadingAwsIcon" />
          <div v-else>...</div>
        </div>

        <CCol sm="5" v-if="!btnToggleLanguage">
          <label>Micro Data (İngilizce) {{ selectedSite.name }}</label>
          <CCard>
            <CCardBody v-if="selectedSite.selected">
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.subSite.microData
                  .en"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.subSite.microData.en.length -
                        1 ==
                      i
                    "
                    @click="microDataAdd('en')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'en')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardBody v-else>
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.microData.en"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.microData.en.length - 1 == i
                    "
                    @click="microDataAdd('en')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'en')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="5" v-if="btnToggleLanguage">
          <label>Micro Data (Almanca) {{ selectedSite.name }}</label>
          <CCard>
            <CCardBody v-if="selectedSite.selected">
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.subSite.microData
                  .de"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.subSite.microData.de.length -
                        1 ==
                      i
                    "
                    @click="microDataAdd('de')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'de')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardBody v-else>
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.microData.de"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.microData.de.length - 1 == i
                    "
                    @click="microDataAdd('de')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'de')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <template #footer>
        <RoleProvider slug="LOCATION_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="locationModalAddUpdateOptions.process == 'add' && canItPass"
              :disabled="locationModalAddUpdateBtnDisable"
              @click="locationAddUpdate(locationModalAddUpdateForm)"
              >Ekle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="LOCATION_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="
                locationModalAddUpdateOptions.process == 'update' && canItPass
              "
              :disabled="locationModalAddUpdateBtnDisable"
              @click="locationAddUpdate(locationModalAddUpdateForm)"
              >Güncelle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="LOCATION_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              v-if="
                locationModalAddUpdateOptions.process == 'update' && canItPass
              "
              color="danger"
              @click="
                selectedSite.selected
                  ? locationDelete({
                      _id: locationModalAddUpdateForm.subSite._id,
                    })
                  : locationDelete({ _id: locationModalAddUpdateForm._id })
              "
              >{{
                selectedSite.selected
                  ? `${selectedSite.name} Lokasyon Sil`
                  : "Sil"
              }}</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Func from "../../func";
import Vue from "vue";
import ckeditor from "ckeditor4-vue"; // https://ckeditor.com/docs/ckeditor4/latest/guide/dev_vue.html#basic-usage.
import { RoleProvider } from "../../provider";
import TranslateAWS from "../../components/TranslateAWS.vue";

Vue.use(ckeditor);
axios.defaults.withCredentials = true;

export default {
  name: "LocationSearch",
  data() {
    return {
      loadingAwsIcon: false,
      subsiteSwitch: false,
      subSiteTitle: null,
      selectedSite: {
        id: "",
        name: "",
        selected: false,
      },
      subSiteInfo: [],
      locationsOfCity: [],
      // AG GID
      ENV_URL_CITY_LOCATION: process.env.VUE_APP_CDN_URL_CITY_LOCATION,
      locationsGridShow: false,
      defaultLocationImage: "default_location.png",
      columnDefs: [
        {
          field: "image",
          valueGetter: (params) => {
            if (params.data.image == "default_location.png") {
              return "YOK";
            } else {
              return "MEVCUT";
            }
          },
          headerName: "Görsel",
          width: 130,
        },
        {
          field: "status",
          cellRenderer: (params) => {
            return params.value ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value
              ? { color: "#2eb85c", fontWeight: "bold" }
              : { color: "#e55353", fontWeight: "bold" };
          },
          headerName: "Durum",
          width: 100,
        },
        {
          field: "footerStatus",
          cellRenderer: (params) => {
            return params.value ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value
              ? { color: "#2eb85c", fontWeight: "bold" }
              : { color: "#e55353", fontWeight: "bold" };
          },
          headerName: "Footer Durumu",
          width: 100,
        },
        {
          field: "city",
          headerName: "Şehir",
          width: 150,
        },
        {
          field: "locationName",
          headerName: "Lokasyon Adı",
          width: 200,
        },
        {
          field: "slug",
          headerName: "Slug",
          width: 400,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      // LOKASYON ARAMA
      searchLocationFormCollapsed: false,
      searchLocationFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      searchLocationForm: {
        status: "",
        city: "",
        locationName: "",
      },
      // LOKASYON EKLEME GÜNCELLEME MODAL
      locationModalAddUpdate: false, // Car Modal Add Update
      locationModalAddUpdateBtnDisable: false, // Car Modal Add Update button disabled enabled değişkeni
      locationModalAddUpdateOptions: {
        // TODO locationModalAddUpdateOptions
        // Car Modal Add Update değişkenleri
        process: "",
        city: "",
        locationName: "",
      },
      locationModalAddUpdateFormStatusOptions: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      locationModalAddUpdateFormThemeOptions: [
        {
          value: false,
          label: "Light",
        },
        {
          value: true,
          label: "Dark",
        },
      ],
      btnToggleLanguage: 0,
      locationModalAddUpdateForm: {
        _id: "",
        status: true,
        footerStatus: true,
        city: "",
        bookingFormLocation: "",
        locationName: "",
        locationNameEn: "",
        locationNameDe: "",
        theme: false,
        iataCode: "",
        slug: "",
        oldSlug: "",
        srcImage: "",
        title: "",
        files: null,
        description: "",
        richText: "",
        campaignImagesValue: [],
        content: {
          tr: {
            title: "",
            description: "",
            richText: "",
          },
          en: {
            title: "",
            description: "",
            richText: "",
          },
          de: {
            title: "",
            description: "",
            richText: "",
          },
        },
        microData: {
          tr: [
            {
              question: "",
              answer: "",
            },
          ],
          en: [
            {
              question: "",
              answer: "",
            },
          ],
          de: [
            {
              question: "",
              answer: "",
            },
          ],
        },
        subSite: {
          microData: {
            tr: [
              {
                question: "",
                answer: "",
              },
            ],
            en: [
              {
                question: "",
                answer: "",
              },
            ],
            de: [
              {
                question: "",
                answer: "",
              },
            ],
          },
          content: {
            tr: {
              title: "",
              description: "",
              richText: "",
            },
            en: {
              title: "",
              description: "",
              richText: "",
            },
            de: {
              title: "",
              description: "",
              richText: "",
            },
          },
          subSiteId: null,
          status: true,
          subSiteName: "",
          locationId: "",
        },
      },
      searchFilterInput: "",
    };
  },
  components: {
    AgGridVue,
    RoleProvider,
    TranslateAWS,
  },
  watch: {
    "locationModalAddUpdateForm.locationName": function () {
      if (
        this.locationModalAddUpdateForm.city != "" &&
        this.locationModalAddUpdateForm.locationName != ""
      ) {
        this.locationModalAddUpdateForm.slug = Func.slugify(
          this.locationModalAddUpdateForm.city
        );
      } else this.locationModalAddUpdateForm.slug = "";
    },

    subsiteSwitch(value) {
      value
        ? this.subSiteInfos()
        : this.cleanSubSiteLocationModalAddUpdateForm();
    },
  },
  methods: {
    async getSubsiteLocation() {
      this.selectedSite.selected = true;
      this.locationModalAddUpdateForm.subSite.subSiteId = this.selectedSite.id;
      this.locationModalAddUpdateForm.subSite.subSiteName =
        this.selectedSite.name;
      this.locationModalAddUpdateForm.subSite.locationId =
        this.locationModalAddUpdateForm._id;
      if (this.locationModalAddUpdateOptions.process === "update") {
        await axios
          .get(process.env.VUE_APP_API_URL + "admin/citylocation/search", {
            params: {
              locationId: this.locationModalAddUpdateForm._id,
              subSiteId: this.selectedSite.id,
            },
          })
          .then((response) => {
            if (response.data.result !== "error")
              this.locationModalAddUpdateForm.subSite = response.data.result;
          });
      }
    },

    cleanSubSiteLocationModalAddUpdateForm() {
      this.selectedSite.selected = false;
      this.selectedSite.id = "";
      (this.selectedSite.name = ""),
        (this.subsiteSwitch = false),
        (this.locationModalAddUpdateForm.subSite = {
          microData: {
            tr: [
              {
                question: "",
                answer: "",
              },
            ],
            en: [
              {
                question: "",
                answer: "",
              },
            ],
            de: [
              {
                question: "",
                answer: "",
              },
            ],
          },
          content: {
            tr: {
              title: "",
              description: "",
              richText: "",
            },
            en: {
              title: "",
              description: "",
              richText: "",
            },
            de: {
              title: "",
              description: "",
              richText: "",
            },
          },
          status: true,
          subSiteId: "",
          subSiteName: "",
          locationId: "",
        });
    },

    async subSiteInfos() {
      try {
        await axios
          .get(process.env.VUE_APP_API_URL + "admin/subsites")
          .then((response) => {
            this.subSiteInfo = response.data.subSites.map((item) => {
              return {
                ...item,
                selectedSiteValue: {
                  name: item.name,
                  id: item._id,
                },
              };
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    async clickAwsTranslate(where, target) {
      const texts = {
        ckeditor: {
          en: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.en.richText
            : this.locationModalAddUpdateForm.content.en.richText,
          tr: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.tr.richText
            : this.locationModalAddUpdateForm.content.tr.richText,
          de: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.de.richText
            : this.locationModalAddUpdateForm.content.de.richText,
          setData: (t, target) => {
            this.locationModalAddUpdateForm.content[target].richText = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm.subSite.content[target].richText =
              t;
          },
        },
        description: {
          en: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.en.description
            : this.locationModalAddUpdateForm.content.en.description,
          tr: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.tr.description
            : this.locationModalAddUpdateForm.content.tr.description,
          de: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.de.description
            : this.locationModalAddUpdateForm.content.de.description,
          setData: (t, target) => {
            this.locationModalAddUpdateForm.content[target].description = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm.subSite.content[
              target
            ].description = t;
          },
        },
        title: {
          en: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.en.title
            : this.locationModalAddUpdateForm.content.en.title,
          tr: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.tr.title
            : this.locationModalAddUpdateForm.content.tr.title,
          de: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.de.title
            : this.locationModalAddUpdateForm.content.de.title,
          setData: (t, target) => {
            this.locationModalAddUpdateForm.content[target].title = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm.subSite.content[target].title = t;
          },
        },
        microData: {
          en: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.microData.en
            : this.locationModalAddUpdateForm.microData.en,
          tr: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.microData.tr
            : this.locationModalAddUpdateForm.microData.tr,
          de: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.microData.de
            : this.locationModalAddUpdateForm.microData.de,
          setData: (t, target) => {
            this.locationModalAddUpdateForm.microData[target] = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm.subSite.microData[target] = t;
          },
        },
        locationname: {
          tr: this.locationModalAddUpdateForm.locationName,
          en: this.locationModalAddUpdateForm.locationNameEn,
          de: this.locationModalAddUpdateForm.locationNameDe,
          setData: (t, target) => {
            this.locationModalAddUpdateForm[
              `locationName${target.charAt(0).toUpperCase()}${target.slice(1)}`
            ] = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm[
              `locationName${target.charAt(0).toUpperCase()}${target.slice(1)}`
            ] = t;
          },
        },
      };
      try {
        const source = "tr";
        const sourceTextOrObject = texts[where][source];
        this.loadingAwsIcon = true;

        if (typeof sourceTextOrObject == "object") {
          let translations = sourceTextOrObject.map(async (item) => ({
            question: await this.awsTranslate(item.question, source, target),
            answer: await this.awsTranslate(item.answer, source, target),
          }));
          translations = await Promise.all(translations);
          this.selectedSite.selected
            ? texts[where].setDataSubSite(translations, target)
            : texts[where].setData(translations, target);
        } else {
          const text = await this.awsTranslate(
            sourceTextOrObject,
            source,
            target
          );
          this.selectedSite.selected
            ? texts[where].setDataSubSite(text, target)
            : texts[where].setData(text, target);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAwsIcon = false;
      }
    },
    async awsTranslate(text, source, target) {
      try {
        const { data } = await axios.post(
          process.env.VUE_APP_API_URL + "admin/aws-translate",
          {
            text,
            source,
            target,
          }
        );
        return data || "error";
      } catch (error) {
        console.log(error.response);
      }
    },
    async fetchLocationsOfCity(params) {
      try {
        let selectedCity = params?.data?.slug;
        await axios
          .get(process.env.VUE_APP_API_URL + `location/finder/${selectedCity}`)
          .then((response) => {
            response.data.locationList.map((location) => {
              this.locationsOfCity.push({
                value: location.slug,
                label: location.locationName,
              });
            });
          });
      } catch (err) {}
    },
    microDataAdd(lang) {
      var microData = {
        question: "",
        answer: "",
      };
      selectedSite.selected
        ? this.locationModalAddUpdateForm.subSite.microData[lang].push(
            microData
          )
        : this.locationModalAddUpdateForm.microData[lang].push(microData);
    },
    microDataDelete(val, lang) {
      Vue.swal
        .fire({
          icon: "question",
          text: "Silmek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var item = selectedSite.selected
              ? this.locationModalAddUpdateForm.subSite.microData[lang].indexOf(
                  val
                )
              : this.locationModalAddUpdateForm.microData[lang].indexOf(val);

            selectedSite.selected
              ? this.locationModalAddUpdateForm.subSite.microData[lang].splice(
                  item,
                  1
                )
              : this.locationModalAddUpdateForm.microData[lang].splice(item, 1);
          }
        });
    },
    lokslug() {
      this.selectedSiteSlug = Func.slugify(
        this.locationModalAddUpdateForm.city
      );
      return this.selectedSiteSlug;
    },

    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    clickImageUpload() {
      // TODO clickImageUpload
      document.getElementById("inputLocationImageFile").click();
    },

    clickImageDelete() {
      // TODO clickImageDelete
      this.locationModalAddUpdateForm.srcImage =
        this.ENV_URL_CITY_LOCATION + this.defaultLocationImage;
      this.locationModalAddUpdateForm.files = null;
      document.getElementById("inputLocationImageFile").value = "";
    },

    selectImage() {
      // TODO selectImage
      const _this = this;
      const fileImage = this.$refs.files.files[0];

      if (fileImage.size > 1048576) {
        Vue.swal({
          icon: "error",
          title: "Lokasyon Ekle - Resim",
          html: "Maksimum 1 MB resim yükleyebilirsiniz!",
          confirmButtonText: "Tamam",
        });
      } else {
        if (
          fileImage.type === "image/png" ||
          fileImage.type === "image/jpg" ||
          fileImage.type === "image/jpeg"
        ) {
          let reader = new FileReader();

          reader.onload = function (e) {
            _this.locationModalAddUpdateForm.srcImage = e.target.result;
            _this.locationModalAddUpdateForm.files = fileImage;
          };
          reader.readAsDataURL(fileImage);
        } else {
          Vue.swal({
            icon: "error",
            title: "Lokasyon Ekle - Resim",
            html: "Yalnızca png, jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
            confirmButtonText: "Tamam",
          });
        }
      }
    },

    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    cleanLocationModalAddUpdateForm() {
      // TODO cleanLocationModalAddUpdateForm
      this.locationsOfCity = [];
      this.locationModalAddUpdateForm._id = "";
      this.locationModalAddUpdateForm.status = true;
      this.locationModalAddUpdateForm.footerStatus = true;
      this.locationModalAddUpdateForm.bookingFormLocation = "";
      this.locationModalAddUpdateForm.city = "";
      this.locationModalAddUpdateForm.locationName = "";
      this.locationModalAddUpdateForm.locationNameEn = "";
      this.locationModalAddUpdateForm.locationNameDe = "";
      this.locationModalAddUpdateForm.theme = false;
      this.locationModalAddUpdateForm.iataCode = "";
      this.locationModalAddUpdateForm.title = "";
      this.locationModalAddUpdateForm.description = "";
      this.locationModalAddUpdateForm.srcImage = "";
      this.locationModalAddUpdateForm.slug = "";
      this.locationModalAddUpdateForm.oldSlug = "";

      this.locationModalAddUpdateForm.content = {
        tr: {
          title: "",
          description: "",
          richText: "",
        },
        en: {
          title: "",
          description: "",
          richText: "",
        },
        de: {
          title: "",
          description: "",
          richText: "",
        },
      };
      this.locationModalAddUpdateForm.microData = {
        tr: [
          {
            question: "",
            answer: "",
          },
        ],
        en: [
          {
            question: "",
            answer: "",
          },
        ],
        de: [
          {
            question: "",
            answer: "",
          },
        ],
      };
      this.locationModalAddUpdateForm.campaignImagesValue = [];
    },

    searchLocation() {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/citylocation/search", {
          params: this.searchLocationForm,
        })
        .then((response) => {
          this.rowData = response.data.locationList;
          this.searchLocationFormCollapsed = false;
          this.locationsGridShow = true;
        });
    },
    locationAddUpdate(params) {
      var formData = new FormData(); // TODO Form data
      if (
        this.locationModalAddUpdateForm.files !== null &&
        this.locationModalAddUpdateForm.files.size > 0
      )
        formData.append("image", this.locationModalAddUpdateForm.files);
      else if (
        this.locationModalAddUpdateForm.srcImage ===
        this.ENV_URL_CITY_LOCATION + this.defaultLocationImage
      )
        formData.append("image", this.defaultLocationImage);

      if (this.locationModalAddUpdateOptions.process === "update") {
        formData.append("_id", params._id);
      }
      if (this.selectedSite.selected == true) {
        formData.append("subSite", JSON.stringify(params.subSite));
      }
      formData.append("content", JSON.stringify(params.content));
      formData.append("status", params.status);
      formData.append("footerStatus", params.footerStatus);

      formData.append("city", params.city);
      formData.append("locationName", params.locationName);
      formData.append("locationNameEn", params.locationNameEn);
      formData.append("locationNameDe", params.locationNameDe);
      formData.append("microData", JSON.stringify(params.microData));
      formData.append(
        "bookingFormLocation",
        JSON.stringify(params.bookingFormLocation)
      );

      // slug oluştururken yaşanan sorun sebebiyle lokasyon girilemiyor. bu şekilde bir çözüm ürettim
      var _slug = Func.slugify(this.locationModalAddUpdateForm.city);

      formData.append("slug", _slug);

      this.$Progress.start();
      this.locationModalAddUpdateBtnDisable = true; // Modal'daki post button'u disable ettik.

      if (this.locationModalAddUpdateOptions.process === "add") {
        axios
          .post(process.env.VUE_APP_API_URL + "admin/citylocation", formData)
          .then((response) => {
            this.$Progress.finish();
            this.locationModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

            if (response.data.result === "success") {
              if (this.locationsGridShow) this.searchLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
              this.locationModalAddUpdate = false; // Modal'ı kapattık
            }

            Vue.swal({
              icon: response.data.result,
              title: "Lokasyon Ekle",
              html: response.data.message,
              confirmButtonText: "Tamam",
            });
          });
      } else if (this.locationModalAddUpdateOptions.process === "update") {
        axios
          .put(process.env.VUE_APP_API_URL + "admin/citylocation", formData)
          .then((response) => {
            this.$Progress.finish();
            this.locationModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

            if (response.data.result === "success") {
              if (this.locationsGridShow) this.searchLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
              this.locationModalAddUpdate = false; // Modal'ı kapattık
            }

            Vue.swal({
              icon: response.data.result,
              title: "Lokasyon Düzenle",
              html: response.data.message,
              confirmButtonText: "Tamam",
            });
          });
      }
    },

    locationDelete(params) {
      this.$Progress.start();
      axios
        .delete(
          process.env.VUE_APP_API_URL + "admin/citylocation/" + params._id
        )
        .then((response) => {
          this.$Progress.finish();
          this.searchLocation();

          if (response.data.result === "success") {
            if (this.locationsGridShow) this.searchLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
            this.locationModalAddUpdate = false; // Modal'ı kapattık
          }
          Vue.swal({
            icon: response.data.result,
            title: "Lokasyon Sil",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        });
    },

    onRowDoubleClicked(params) {
      this.fetchLocationsOfCity(params);
      console.log(params);
      var _this = this;
      _this.cleanLocationModalAddUpdateForm();
      _this.cleanSubSiteLocationModalAddUpdateForm();
      _this.locationModalAddUpdateOptions.process = "update";
      _this.locationModalAddUpdateOptions.title = "Şehir Lokasyonunu Güncelle";
      _this.locationModalAddUpdateForm._id = params.node.data._id;

      _this.locationModalAddUpdateForm.title = params.node.data.title;
      _this.locationModalAddUpdateForm.description =
        params.node.data.description;
      _this.locationModalAddUpdateForm.srcImage =
        _this.ENV_URL_CITY_LOCATION + params.node.data.image;

      _this.locationModalAddUpdateForm.files = null;
      _this.locationModalAddUpdateForm.campaignImagesValue =
        params.node.data.campaignImagesValue;

      _this.locationModalAddUpdateForm.status = params.node.data.status;
      _this.locationModalAddUpdateForm.footerStatus =
        params.node.data.footerStatus;

      _this.locationModalAddUpdateForm.city = params.node.data.city;
      _this.locationModalAddUpdateForm.bookingFormLocation =
        params?.node?.data?.bookingFormLocation?.slug;

      _this.locationModalAddUpdateForm.locationName =
        params.node.data.locationName;
      _this.locationModalAddUpdateForm.locationNameEn =
        params.node.data.locationNameEn;
      _this.locationModalAddUpdateForm.locationNameDe =
        params.node.data.locationNameDe;
      _this.locationModalAddUpdateForm.theme = params.node.data.theme;
      _this.locationModalAddUpdateForm.iataCode = params.node.data.iataCode;
      _this.locationModalAddUpdateForm.slug = params.node.data.slug;

      _this.locationModalAddUpdateForm.oldSlug = params.node.data.slug;
      _this.locationModalAddUpdate = true;

      if (params.node.data.microData) {
        _this.locationModalAddUpdateForm.microData = params.node.data.microData;
      }
      if (params.node.data.content) {
        // _this.locationModalAddUpdateForm.content.tr =
        //   params.node.data.content.tr;
        // _this.locationModalAddUpdateForm.content.en =
        //   params.node.data.content.en;
        // _this.locationModalAddUpdateForm.content.de =
        //   params.node.data.content.de;

        _this.locationModalAddUpdateForm.content.tr.richText =
          params?.node?.data?.content?.tr?.richText || "test";
        _this.locationModalAddUpdateForm.content.tr.title =
          params?.node?.data?.content?.tr?.title || "test";
        _this.locationModalAddUpdateForm.content.tr.description =
          params?.node?.data?.content?.tr?.description || "test";

        _this.locationModalAddUpdateForm.content.en.richText =
          params?.node?.data?.content?.en?.richText || "test";
        _this.locationModalAddUpdateForm.content.en.title =
          params?.node?.data?.content?.en?.title || "test";
        _this.locationModalAddUpdateForm.content.en.description =
          params?.node?.data?.content?.en?.description || "test";

        _this.locationModalAddUpdateForm.content.de.richText =
          params?.node?.data?.content?.de?.richText || "test";
        _this.locationModalAddUpdateForm.content.de.title =
          params?.node?.data?.content?.de?.title || "test";
        _this.locationModalAddUpdateForm.content.de.description =
          params?.node?.data?.content?.de?.description || "test";
      }
    },
  },
  created: function () {
    this.searchLocation();
  },
};
</script>

<style scoped>
.not-found-photo {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 200px;
}
.red {
  background-color: red !important;
  color: white !important;
}
.select {
  margin-top: -5px;
  margin-bottom: -5px;
}
.topRow {
  margin-top: -15px !important;
  margin-bottom: 5px !important;
}
</style>
