var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        padding: "0.4rem 0.8rem",
        border: "1px solid grey",
        "border-radius": "5px",
        cursor: "pointer",
      },
      on: { click: _vm.onClickIcon },
    },
    [_c("v-icon", [_vm._v("mdi-google-translate ")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }