<template>
  <div
    style="
      display: flex;
      align-items: center;
      padding: 0.4rem 0.8rem;
      border: 1px solid grey;
      border-radius: 5px;
      cursor: pointer;
    "
    @click="onClickIcon"
  >
    <v-icon>mdi-google-translate </v-icon>
    <!--<p class="m-0">İngilizceye Ata</p>-->
  </div>
</template>
<script>
export default {
  name: "TranslateAWS",
  props: {
    onClickIcon: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss"></style>
